/********************************* 
Qrata Theme scss 
Author: INSONIX    
Year :2020
**********************************/

body {
    font-family: 'Roboto'!important;
    background: #f1f1f1!important;
}

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

/* Your customizations here */
html {
    scroll-behavior: smooth;
  }
$theme-colors: 
( 
primary:#00b59d, 
info:#3c4043,
secondary:#018e7e,
dark:#12493d, 
light:#d1d1d1,
warning:#f8c347,
success:#28a745,
white:#fff, 
danger: #b13221);

$colors:(
    primary:#00b59d, 
    info:#3c4043,
    secondary:#018e7e,
    dark:#12493d, 
    light:#d1d1d1,
    warning:#f8c347,
    success:#28a745,
    white:#fff, 
    danger: #b13221);

$defaultOverride : #674aee;

@import "bootstrap";
a.nav-link {
    text-decoration: none;
    color: #00796B;
    transition: color 0.2s ease-out;
}
/*********** dashboard App icon css********/
.app-icon {
    width: 125px;
    max-width:100%;
}

.default {
    color:$defaultOverride;
}
// .active {
//     color: #004D40!important;
//     background: #E0F2F1;
//     .text-light {
//         color: #223444!important;
//     }
// }

.sub-menu {
    .nav-link {
        padding-left: 40px!important;
    }
}

.page-item.active .page-link{
        background: $defaultOverride;
}

.nav-icon {
    z-index: 1;
}

.sidebar {
    min-width: 17%;
    max-width: 17%;
    background: #fff;
    color: #adb5bd;
    margin-left: -17%;
    transition: all 0.5s;
    position: fixed;
    height: 100%;
    overflow: auto;
    z-index:999;
    .nav-link {
        display: block;
        padding: 10px 9px;
    }
}

.sidebar.is-open {
    margin-left: 0;
    transition: .5s;
}

.sidebar-header>button {
    position: relative;
    float: right;
    margin: .5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}

.nav-icons {
    min-width: 30px;
    display: inline-block;
 
}

    .arrow {
        top: -15px !important;
      }



.dropdown-menu-right {
    border: 0px;
    box-shadow: 0 .125rem 1.25rem rgba(0, 0, 0, .075)!important;
}

.cursor-pointer {
    cursor: pointer;
}

/******** nav-item dropdown active bg******************/
.dropdown-item.active, .dropdown-item:active {
     background-color: #674aee;
}
/*************saved assets toggle caret hide ************/
.save-assets 
{
    right: 0px;
    top: 0px;
.dropdown-toggle::after {
    display: none;
}
.dropdown-menu.show {
    display: block;
    min-width: 250px;
} 

}

    
.captcha-number {
  
    background-color: #e9ecef;
  
}
/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */

.content {
    padding: 20px;
    margin-left: 0;
    transition: all 0.5s;
}

.content.is-open {
    margin-left: 17%;
    width: 83%;
}

.info-box-icon {
    font-size: 50px;
    color: #ffffff30;
}

.btn:focus {
    outline: none !important;
    box-shadow: none;
}

@media only screen and (max-width: 575.98px) {
    .content.is-open {
        /*margin-left: 100%;*/
        display: none;
        transition: all 0.5s;
    }
    .sidebar.is-open {
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
        transition: all 0.5s, height 0s;
        height: 100vh !important;
    }
    .sidebar.is-open>.sidebar-header button {
        display: block;
    }
    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }
}
.bg-dark
{
    label{
        color:white;
    }
}
/**************** Advertisement banner css ******************/
.qrataads-728-90 {
    width: 728px;
    height: 90px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}
.qrataads-320-50 {
    width: 320px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}
.qrataads-300-250 {
    width: 300px;
    height: 250px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}
.qrataads-200-200 {
    width: 200px;
    height: 200px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
}
.price-tag {
    font-size: 55px;
}

/************** search page tool-bar***************/
.score-box {
    width: 40px;
    height: 40px;
    transform: rotate(45deg);
}
.s-number {
    transform: rotate(-45deg);
}
.border-left-tool {
    // height: calc(100vh - 160px);
    height: calc(100% - 125px);
    border-left: 2px solid #018e7e;
    left: 34px;
    top: 18px;
}
/************** supporthelp***************/
.date-time {
    right: 15px;
    top: 6px;
}
/* ---------------------------------------------------
      Scroll Bar  STYLE
  ----------------------------------------------------- */
  
/* width */

 ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    cursor: pointer;
}


/* Track */

 ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-right: 1px solid #7e929f;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #7e929f;
    border-radius: 10px;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #7e929f;
    ;
}


/* Handle on hover */

 ::-moz-scrollbar-thumb:hover {
    background: rgb(167, 159, 159);
}


/* width */

 ::-moz-scrollbar {
    width: 3px;
}


/* Track */

 ::-moz-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-right: 2px solid #E93329;
}


/* Handle */

 ::-moz-scrollbar-thumb {
    background: #E93329;
    border-radius: 10px;
}

.resizer {
    display: inline-block;
    width: 2px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action: none;
    &.isResizing {
        background: grey;
    }
}

.user {
    background-color: red;
    font-size: large;
    text-align: right;
}

/**************  Qrata-step-form  bottom progress bar css *****************/
#qrata-step-form {
    padding: 0;
    list-style-type: none;
     font-size: 12px;
    clear: both;
    line-height: 1em;
    margin: 0 -1px;
    text-align: center;
 li {
    float: left;
    padding: 3px 30px 3px 40px;
    background: #d1d1d1;
    color: #fff;
    position: relative;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    width: 100%;
    margin: 0 1px;
    margin-top: 15px;
}
li:before {
    content: '';
    border-left: 6px solid #fff;
    border-top: 3px solid transparent;
    border-bottom: 4px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
}
li:after {
    content: '';
    border-left: 6px solid #d1d1d1;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    position: absolute;
    top: -1px;
    left: 100%;
    z-index: 20;
}
 li.active {
    background: #00b59d;
}
 li.active:after {
    border-left-color: #00b59d;
}
}
.d-content {
    display:contents;
}
/********************** loader css **************/
.page-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 9999;
}
.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0,0,0,.5);
}
/* Bio page Css */
.user-img {
   
    display: block;
    position: relative;
    width:120px;
    height:120px;

    img {
width:120px;
height:120px;
    }
  
}
.upload-btn {
    position: absolute;
    bottom: 0px;
    right: 5px;
}
// BIO DISPLAY NAME
.display-name-input {
    height: 35px;
    border: 0;
    background: none;
    color: white;
    border-bottom: 2px solid white;
    outline: 0;

    &::placeholder {
        color:white;
    }
}
// BIO TEXT SAVE BUTTON
.save-bio-btn {
    font-size: 14px;
    width: 70px;
    background: #00b59d;
    border-radius: 5px;
    color: white;
    padding: 2px 3px;
}

//BIO TOGGLE BUTTON 
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;

  
 input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #018e7e;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #018e7e;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

}

/*** datepicker css *********/

.react-datepicker-popper
{
    z-index: 999!important;
}

.ql-container.ql-snow {
    border: 0px !important;
}

.w-80px{
    width: 80px !important;
}

.outline-focus-0:focus {
    outline: 0;
}

.success-icon
{
    font-size:92px;
}

#menu {
    position: absolute !important;
     background: #e9ecef;
     text-decoration: none;
     left: 359px;
     list-style: none;
     top: 208px;
     border: 1px solid #d1d1d1;
     border-radius: 2px;

     li {
        padding: 5px;
        border-bottom: 1px solid #d1d1d1;

        &:hover{
            background: #c4c8cc;
           cursor: pointer;
        }
     }
}

// Custom DropDown List
.dropdown-container {
    width:100%;
    display: inline-block;
    cursor: pointer;
    text-indent: 5px;
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    position: relative;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    .heading {
        .selectedValue {
            font-weight: 400;
            color: #495057;
            text-align: left;
            line-height: 1.5;
            font-size: 1rem;
        }
    }
    .dropdown-wrapper {
        position: absolute;
        background: white;
        width: 100%;
        margin-left: -11px;
        margin-top: 8px;
        padding: 3px 2px;
        font-size: 12px;
        border: 1px solid #ced4da;
        display: none;
        z-index: 2;
        .dropDown-list{
            list-style: none;
            margin: 0;
            padding: 0;
        
            li:hover {
                color:grey;
                font-weight: 600;
                cursor: pointer;
            }
            .inner-data{
                list-style: none;
                margin: 0;
                li:hover {
                    color:grey;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
        }
    }
    
}


/*************  Table Header - Footer Sticky css *************/

.table-scroll{
    max-height:75vh;  
     overflow:auto;
      thead tr:nth-child(1) th{
         background: white;
         position: sticky;
         top: 0;
         z-index: 10;
         border:0px;
       }
       thead tr:nth-child(2) th{
         background: white;
         position: sticky;
         top: 32px;
         z-index: 10;
         border:0px;
       
       }
  
       tfoot tr:nth-child(1) td{
           background: white;
           position: sticky;
           bottom: 0;
           z-index: 10;
         }
   }
   .inner-table
   {
     thead tr:nth-child(1) th{
         background: white;
         position: sticky;
         top: 0;
         z-index: 1;
       } 
   }



.sorting-up
{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-top: 3px solid #f2de43 !important;
}

.sorting-down
{
    width:100%;
    position:absolute;
    bottom:0;
    left: 0;
    border-bottom: 3px solid #f2de43 !important;
}

.more-button-search {
    line-height: 1;
    button {
        padding: 0;
        border: 0 !important;
        background-color: transparent !important;
        color: #018e7e !important;
        outline: 0 !important;
        box-shadow: 0 none !important;

        &:hover , &:active , &:visited {
            background-color: transparent !important;
            color: #018e7e !important;
            box-shadow: 0 none !important;
        }
    }
}


/*************** home-page-css **********************/

.home-banner
{
    background-image: url('../src/assets/images/background-banner.jpg');
    width:100%;
    height:500px;
    background-size: cover;
    border-radius:0px;
}


p {
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 30px;
}
.fs-20
{
    font-size:20px;
}
.about-exp
{
    padding:100px;
}
.search-banner-sec {
    width: 100%;
}
.qrata-login
{
    background-image: url('../src/assets/images/home/flex-bg.jpg');
    width:100%;
    height:600px;
    background-size: cover;
    border-radius:0px;
    background-attachment:fixed; 
}

/*************** Inquiry -page-css **********************/
.shadow-custom
{ box-shadow: 0 0 26px 0px #f8c347!important;}
.bg-grey-gradient
{
    background: radial-gradient(circle, rgba(167,171,174,1) 25%, rgba(58,59,59,1) 100%);
    background-image: url('../src/assets/images/background-banner.jpg');
      background-size: cover;  
      border-radius: 0px;
}


a {
  color: white;
  text-decoration: none;
}
.arrow {
  text-align: center;
  margin: 8% 0;
}
.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}




@media(max-width:768px)
{
    .about-exp
{
    padding:20px;
}
}


.login-captcha{
    div {
        display:flex;
        flex-wrap: initial;
        input {
            height: calc(1.8em + 0.75rem + 2px);
            width: 154px;
            border: 1px solid #ced4da;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            outline: 0;
            background-clip: padding-box;
            border-radius: 4px;
        }
    }
}
.w-95 {
    width: 95% !important;
}

// CountryCode DropDown

.countryCode{
    border : 0px;
    outline : 0;
    border-bottom: 1px solid #d1d1d1;
    background: transparent;
    width: 70px;
}

/*************** comments attachment upload css  start***********/
.attachment-icon 
{
    position: absolute;
    right: 30px;
    top: 6px;
    border: 0px;
    color: #9a9595;

    .form-group {
        background: #fff;
        margin-bottom: 0px;
        height: 30px;
        position: absolute;
        min-width: 20px;
    }
    .custom-file-label {
        position: absolute;
        top: -37px;
        right: 68px;
        left: -197px;
        z-index: 1;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        border: 0px solid #ced4da;
        border-radius: 0.25rem;
        width: 200px;
        height: 34px;
    }
    .custom-file-input:focus ~ .custom-file-label {
        border-color: transparent;
        box-shadow: 0 0 0 0.2rem transparent;
    }
    .custom-file-label[data-browse]::after {
        content: attr(data-browse);
        display: none;
    }
}
/*************** comments attachment upload css  end***********/

// .rc-tree-node-selected {
//      background-color: transparent!important; 
//      border: none!important;  
//      }

.rc-tree-node-selected {
    background-color: #ffe6b0;
    border: 1px #ffb951 solid;
    opacity: 0.8;
    padding: 5px 15px!important;
    display: inline-flex!important;
    height: 32px!important;
    align-items: center;
}
    /************* Google-logo css ***********/  
    
    .Glogo-s {
       position: absolute;
       right: 10px;
     
       img {
           width: 45px;
       }
    }
    .Q-serach-data {
        a 
        {
            color: #000;
        }
        
    }
    /*************** contactus page css  *************/ 
    .bg-c-address
    {
        background:#0d5143;
    }
    
    .b-logo{
        transform: rotate(
    -47deg);
    }
    /************ chat-btn-css ******/ 
    .chat-box-btn
    {
     right: 8px;
    bottom: 8px;
    cursor: pointer;
    }
    .bookmark-box-btn
    {
        right: 8px;
        top: 12px;
        cursor: pointer;  
    }
    /********   react search custom icon css **********************/
    .select-search-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }
    /***************** add new folder options css ***************/  
    #menu {
        z-index: 9999;
    }

